const routes = [
  {
    path: "umsatzuebersicht",
    name: "Umsatzuebersicht",
    component: () => import("@/view/moderation/Overview.vue"),
  },
  {
    path: "agenten",
    name: "Agenten",
    component: () => import("@/view/moderation/Mods.vue"),
  },
  {
    path: "bilder",
    name: "Bilder",
    component: () => import("@/view/moderation/Images.vue"),
  },
  {
    path: "agent/:id",
    name: "Agent",
    component: () => import("@/view/moderation/ModDetail.vue"),
  },
  {
    path: "agenten-online",
    name: "AgentenOnline",
    component: () => import("@/view/moderation/ModsOnline.vue"),
  },
  {
    path: "agenten-tickets",
    name: "AgentenTickets",
    component: () => import("@/view/moderation/ModTickets.vue"),
  },
  {
    path: "ticket/bearbeiten/:id",
    name: "AgentenTicketsBearbeiten",
    component: () => import("@/view/moderation/TicketDetail.vue"),
  },
  {
    path: "live-dialoge",
    name: "Live-Dialoge",
    component: () => import("@/view/moderation/LiveChats.vue"),
  },
];

export default routes;
