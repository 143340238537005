// Composables
import { createRouter, createWebHistory } from "vue-router";
import agencyRoutes from "./agencyRoutes";
import AuthenticationService from "@/services/AuthenticationService";

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/view/panel/Dashboard.vue"),
    beforeEnter: AuthenticationService.checkAuthentication,
  },
  {
    path: "/panel",
    name: "panel",
    component: () => import("@/view/panel/Panel.vue"),
    beforeEnter: AuthenticationService.checkAuthentication,
  },
  {
    path: "/waitings",
    name: "waitings",
    component: () => import("@/view/moderation/WaitingRoom.vue"),
    beforeEnter: AuthenticationService.checkAuthentication,
  },
  {
    name: "Agency",
    path: "/agentur",
    component: () => import("@/layout/withAdminLayout.vue"),
    children: [...agencyRoutes],
    beforeEnter: AuthenticationService.checkAuthentication,
  },
  {
    path: "/auth",
    component: () => import("@/layout/withAuthLayout.vue"),
    // Add before enter and redirect
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
          import(
            /* webpackChunkName: "login" */ "@/view/authentication/SignIn.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
