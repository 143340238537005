import PersistenceService from "@/services/PersistenceService";
import _ from "lodash";
import axios from "axios";
import { useUserStore } from "@/store/modules/user";
import { useRouter } from "vue-router";

const userIsLoggedIn = () =>
  PersistenceService.getFromLocalStorage("userAccessToken");

export default {
  checkAuthentication(to, from, next) {
    if (_.isEmpty(userIsLoggedIn())) {
      next("/login");
    } else {
      const userStore = useUserStore();
      const router = useRouter();

      const config = {
        headers: { Authorization: `Bearer ${userStore.accessToken}` },
      };

      const apiUrl = process.env.VUE_APP_API_URL;

      axios
        .get(`${apiUrl}/api/v1/info`, config)
        .then((response) => {
          if (response.status === 200) {
            userStore.update(response.data.user);
          } else {
            next("/login");
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            userStore.logout();
            router.push("/login");
          } else {
            console.log(error);
          }
        });
      

      next();
    }
  },
};
