// Utilities
import { defineStore } from "pinia";
import PersistenceService from "../../services/PersistenceService";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      userAccessToken:
        PersistenceService.getFromLocalStorage("userAccessToken") || "",
      data: PersistenceService.getFromLocalStorage("user") || "",
    };
  },
  getters: {
    isLoggedIn: (state) => !!state.userAccessToken,
    accessToken: (state) => state.userAccessToken,
    user: (state) => state.data,
  },
  actions: {
    login(token, user) {
      this.userAccessToken = token;
      this.data = user;
      PersistenceService.writeToLocalStorage("userAccessToken", token);
    },
    update(user) {
      this.data = user;
      PersistenceService.writeToLocalStorage("user", user);
    },
    logout() {
      this.userAccessToken = null;
      this.data = null;
      localStorage.removeItem("userAccessToken");
    },
  },
});
