import _ from "lodash";

export default {
  getFromLocalStorage(key) {
    let value = localStorage.getItem(key);

    try {
      // Parse arrays, objects, booleans, numbers or stringified number
      value = JSON.parse(value);
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        throw error.message;
      }
    }

    return value;
  },
  writeToLocalStorage(key, value) {
    console.log("token", value);
    if (typeof value !== "undefined" && value !== null) {
      // Only stringify non string values and stringified number
      localStorage.setItem(
        key,
        !_.isString(value) || !isNaN(value) ? JSON.stringify(value) : value
      );
    }
  },
  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  },
  getLegalContent() {
    if (localStorage.getItem("content")) {
      try {
        return JSON.parse(localStorage.getItem("content"));
      } catch (e) {
        localStorage.removeItem("content");
      }
    }

    return null;
  },
};
