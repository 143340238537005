import app from "./config/configApp";
import router from "./routes";
import store from "@/vuex/store";
import pinia from "./store";
import "./static/css/style.css";
// Vue 3rd party plugins
import "@/core/plugins/ant-design";
import "@/core/plugins/fonts";
import "@/core/components/custom";
import "@/core/components/style";

app.config.productionTip = false;
app.use(store);
app.use(pinia);
app.use(router);
app.mount("#app");
